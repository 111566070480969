import { Chip } from "@mui/material";
import getClassName from "classnames";
import { ScaleOrdinal, scaleOrdinal, schemePastel1, stratify } from "d3";
import { filter, flatMap, uniqBy } from "lodash-es";
import { Tree as PrimeReactTree, TreeMultipleSelectionKeys, TreeNodeTemplateOptions, TreeProps } from "primereact/tree";
import { TreeNode } from "primereact/treenode";
import * as React from "react";
import { getPrefixed } from "@triply/utils/prefixUtils.js";
import { getLocalName } from "#containers/Schema/utils.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import { Prefixes } from "../../reducers/datasetManagement";
import * as styles from "./index.scss";

export interface Item {
  id: string;
  parent: string;
  label?: string;
  conceptScheme?: string;
  conceptSchemeLabel?: string;
}

interface InternalItem {
  key: string;
  iri: string;
  parentKey: string;
  label: string | undefined;
  conceptScheme: string | undefined;
  conceptSchemeLabel: string | undefined;
}

const getTreeItems: (allItems: Item[], item: Item, parentIris: string[]) => InternalItem[] = (
  allItems,
  item,
  parentIris,
) => {
  const subItems = parentIris.includes(item.id) ? [] : allItems.filter((s) => s.parent === item.id);
  return [
    {
      key: [...parentIris, item.id].join(">"),
      iri: item.id,
      parentKey: parentIris.join(">"),
      label: item.label,
      conceptScheme: item.conceptScheme,
      conceptSchemeLabel: item.conceptSchemeLabel,
    },
    ...flatMap(subItems, (subItem) => {
      return getTreeItems(allItems, subItem, [...parentIris, item.id]);
    }),
  ];
};

export const getHierarchy = (data: Item[] | undefined, currentItemIri: string, prefixes: Prefixes) => {
  if (!data) return {};

  const treeData = getTreeItems(
    data,
    {
      id: "tree:root",
      parent: "",
    },
    [],
  );

  let hierarchy;

  try {
    hierarchy =
      treeData.length > 0
        ? stratify<InternalItem>()
            .id((item) => item.key)
            .parentId((item) => item.parentKey)(treeData)
        : undefined;
  } catch (e) {
    console.error(e);
  }

  const selectedKeys: TreeMultipleSelectionKeys = {};

  hierarchy?.eachBefore((node) => {
    (node as any).label = node.data.label || getPrefixed(node.data.iri, prefixes) || node.data.iri;
    (node as any).key = node.data.key;

    if (node.data.iri === currentItemIri) selectedKeys[node.data.key] = true;
  });

  hierarchy?.sort((a: any, b: any) => {
    if ((a.data.label && b.data.label) || (!a.data.label && !b.data.label)) {
      return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
    } else if (a.data.label) {
      return -1;
    } else {
      return 1;
    }
  });

  return { hierarchy, selectedKeys };
};

type Props = {
  items: Item[] | undefined;
  currentItemIri: string;
  className?: string;
} & Pick<TreeProps, "emptyMessage" | "onSelect" | "onToggle" | "expandedKeys">;

const Tree: React.FC<Props> = ({
  items,
  currentItemIri,
  emptyMessage,
  expandedKeys,
  onSelect,
  onToggle,
  className,
}) => {
  const prefixes = useDatasetPrefixes();
  const conceptSchemeColor = scaleOrdinal(schemePastel1);

  const { hierarchy, selectedKeys } = getHierarchy(items, currentItemIri, prefixes);

  const nodeTemplate = (node: TreeNode, options: TreeNodeTemplateOptions) => {
    let chipLabel = undefined;
    if (node.data.conceptScheme) {
      chipLabel = getLocalName(node.data.conceptScheme);
    }
    if (node.data.conceptSchemeLabel) {
      chipLabel = node.data.conceptSchemeLabel;
    }
    return (
      <div className={options.className}>
        {node.label}{" "}
        {chipLabel && (
          <Chip
            size="small"
            label={chipLabel}
            style={{
              backgroundColor: `${conceptSchemeColor(node.data.conceptSchemeLabel || node.data.conceptScheme)}`,
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className={getClassName(styles.prime, className)}>
      <PrimeReactTree
        value={hierarchy?.children || []}
        expandedKeys={expandedKeys}
        onToggle={onToggle}
        dragdropScope=""
        emptyMessage={emptyMessage}
        nodeTemplate={nodeTemplate}
        selectionMode="multiple"
        onSelect={onSelect}
        selectionKeys={selectedKeys}
      />
    </div>
  );
};

export default Tree;
