import { Alert, Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import { isEmpty } from "lodash-es";
import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Routes } from "@triply/utils";
import { Account, DatasetPublic, PipelineConfigV02 } from "@triply/utils/Models.js";
import AccessLevelIcon from "#components/AccessLevels/Icon.tsx";
import { substringMatch } from "#components/Highlight/index.tsx";
import { Avatar, Button, FontAwesomeIcon, FormField, Highlight, LoadingButton } from "#components/index.ts";
import { QueryVariableField } from "#components/QueryVariableUtils/QueryVariableField.tsx";
import { validateIri } from "#containers/DataModel/Forms/helpers.ts";
import { fetchJson } from "#helpers/fetch.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import useMemoizedFetch from "#helpers/hooks/useMemoizedFetch.ts";
import { getLoggedInUser } from "#reducers/auth.ts";
import { GlobalState } from "#reducers/index.ts";
import { showNotification } from "#reducers/notifications.ts";
import * as styles from "./style.scss";

type FetchedDataset = Pick<DatasetPublic, "id" | "name" | "displayName" | "accessLevel" | "avatarUrl" | "owner">;

type VariableValues = { [variable: string]: string | undefined };

interface QueryJobFormInput {
  sourceDataset: FetchedDataset;
  targetDataset: FetchedDataset;
  targetGraphName?: string;
  owner: string;
  variables: VariableValues;
}

function getDatasetLabel(dataset: FetchedDataset) {
  return dataset.owner.accountName + " / " + dataset.name;
}

const QueryJobForm: React.FC<{
  onCancel: React.EventHandler<React.MouseEvent<any>>;
}> = ({ onCancel }) => {
  const [sourceDatasetInputValue, setSourceDatasetInputValue] = React.useState("");
  const [sourceDatasetOptions, setSourceDatasetOptions] = React.useState<FetchedDataset[]>([]);
  const [targetDatasetInputValue, setTargetDatasetInputValue] = React.useState("");
  const [targetDatasetOptions, setTargetDatasetOptions] = React.useState<FetchedDataset[]>([]);
  const [error, setError] = React.useState<string | null>();

  const dispatch = useDispatch();
  const constructUrlToApi = useConstructUrlToApi();
  const constructConsoleUrl = useConstructConsoleUrl();
  const datasetFetch = useMemoizedFetch<FetchedDataset[]>();
  const query = useSelector((state: GlobalState) => state.queries.current);
  const loggedInAccount = useSelector((state: GlobalState) => getLoggedInUser(state));
  const accountOptions = [loggedInAccount as Account].concat(loggedInAccount?.orgs || []);

  const { control, handleSubmit, formState, setValue, getFieldState, watch } = useForm<QueryJobFormInput>({
    defaultValues: {
      sourceDataset: query?.dataset as FetchedDataset,
      targetDataset: query?.dataset as FetchedDataset,
      targetGraphName: constructConsoleUrl({
        pathname: `/${query?.dataset?.owner.accountName}/${query?.dataset?.name}/graphs/${query?.name}/${query?.version}`,
      }),
      owner: accountOptions[0].accountName,
      variables: {},
    },
    mode: "onChange",
  });

  const testValues = watch("variables");

  const isTargetGraphNameTouched = getFieldState("targetGraphName", formState).isTouched;

  const datasetUrl = constructUrlToApi({
    pathname: `/datasets?limit=50&fields=id,name,displayName,accessLevel,avatarUrl,owner&verbose`,
    fromBrowser: true,
  });

  const searchDatasets = React.useCallback(
    (field: "source" | "target" | "all", queryString?: string) => {
      const url = queryString ? `${datasetUrl}&q=${encodeURIComponent(queryString)}` : datasetUrl;
      datasetFetch(url)
        .then((results) => {
          if (results && Array.isArray(results)) {
            if (field === "source") setSourceDatasetOptions(results);
            else if (field === "target") setTargetDatasetOptions(results);
            else {
              setSourceDatasetOptions(results);
              setTargetDatasetOptions(results);
            }
          }
        })
        .catch((e: unknown) => {
          console.error(e);
        });
    },
    [datasetUrl, datasetFetch],
  );

  const onSubmit: SubmitHandler<QueryJobFormInput> = (data) => {
    setError(null);
    if (query) {
      const createQueryJobPayload: PipelineConfigV02 = {
        queries: [
          {
            name: `${query.owner.accountName}/${query.name}`,
            version: query.version,
            variables: isEmpty(data.variables) ? undefined : data.variables,
          },
        ],
        sourceDataset: `${data.sourceDataset?.owner.accountName}/${data.sourceDataset?.name}`,
        targetDataset: `${data.targetDataset?.owner.accountName}/${data.targetDataset?.name}`,
        targetGraphName: data.targetGraphName,
        version: 0.2,
      };

      const pipelineCreateUrl = constructUrlToApi({
        pathname: `/pipelines/${data.owner || loggedInAccount?.accountName}`,
        fromBrowser: true,
      });

      fetchJson<Routes.pipelines._account.Post>(pipelineCreateUrl, {
        method: "POST",
        credentials: "same-origin",
        jsonBody: createQueryJobPayload,
      })
        .then((response) => {
          if ("json" in response) {
            dispatch(showNotification("Query job successfully created", "success"));
            dispatch(
              connectedReactRouter.push({ pathname: `/${data.owner || loggedInAccount?.accountName}/-/settings/jobs` }),
            );
          } else {
            if (typeof response.errorBody === "string") setError(response.errorBody);
            else if ("message" in response.errorBody) setError(response.errorBody.message);
            else setError("An error occured in creating query job");
          }
        })
        .catch((e: unknown) => {
          dispatch(showNotification("An error occured in creating query job", "error"));
          console.error(e);
        });
    }
  };

  React.useEffect(() => {
    searchDatasets("all");
  }, [searchDatasets]);

  const sourceDatasetLabelId = React.useId();
  const targetDatasetLabelId = React.useId();
  const targetGraphNameLabelId = React.useId();
  const ownerLabelId = React.useId();

  const getQueryString = React.useCallback(
    () => query?.requestConfig?.payload.query,
    [query?.requestConfig?.payload.query],
  );

  if (!query) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={getClassName("px-5 pb-5 flex", styles.form)}>
      <FormField label="Query" className={getClassName("px-5 pb-5")} helperText="Query name / version">
        {query?.name} / {query?.version}
      </FormField>

      {query.variables && query.variables.length > 0 && (
        <FormField label="Variables" className={getClassName("px-5 pb-5")} helperText="Query variables">
          {query.variables?.map((variableDefinition) => (
            <div key={variableDefinition.name} className={getClassName("pb-5")}>
              <Controller
                name={`variables.${variableDefinition.name}`}
                control={control}
                rules={variableDefinition.required ? { required: "This variable is required " } : undefined}
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <QueryVariableField
                    testValue={testValues[variableDefinition.name]}
                    onTestValueChange={(value) => {
                      onChange(value);
                    }}
                    variableDefinition={variableDefinition}
                    datasetPath={`${query.dataset?.owner.accountName}/${query.dataset?.name}`}
                    getQueryString={getQueryString}
                    required={variableDefinition.required}
                    fieldVariant="standard"
                  />
                )}
              />
            </div>
          ))}
        </FormField>
      )}

      <FormField label="Source dataset" className={getClassName("px-5 pb-5")} inputId={sourceDatasetLabelId}>
        <Controller
          name="sourceDataset"
          control={control}
          rules={{
            required: "A source dataset is required.",
          }}
          render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
            <Autocomplete
              id={sourceDatasetLabelId}
              options={sourceDatasetOptions}
              filterOptions={(x) => x}
              onChange={(_e, data) => onChange(data)}
              renderInput={(params) => (
                <TextField {...(params as any)} error={!!error} helperText={error?.message} required />
              )}
              noOptionsText="No datasets found"
              isOptionEqualToValue={(option: FetchedDataset, value: FetchedDataset) => {
                return option.id === value.id;
              }}
              getOptionLabel={(option: FetchedDataset) => getDatasetLabel(option)}
              renderOption={(props, option: FetchedDataset) => {
                return (
                  <MenuItem {...props}>
                    <Avatar
                      size="sm"
                      className="mr-3"
                      avatarUrl={option.avatarUrl}
                      avatarName={option.displayName || option.name}
                      alt=""
                    />
                    <Highlight
                      fullText={getDatasetLabel(option)}
                      highlightedText={sourceDatasetInputValue}
                      matcher={substringMatch}
                    />
                    <AccessLevelIcon level={option.accessLevel} size="xs" type="dataset" className="ml-3" />
                  </MenuItem>
                );
              }}
              onInputChange={(_event, newInputValue) => {
                searchDatasets("source", newInputValue);
                setSourceDatasetInputValue(newInputValue);
              }}
              {...rest}
            />
          )}
        />
      </FormField>
      <FormField label="Target dataset" className={getClassName("px-5 pb-5")} inputId={targetDatasetLabelId}>
        <Controller
          name="targetDataset"
          control={control}
          rules={{
            required: "A target dataset is required.",
          }}
          render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
            <Autocomplete
              id={targetDatasetLabelId}
              options={targetDatasetOptions}
              filterOptions={(x) => x}
              onChange={(_e, data) => {
                onChange(data);
                if (data && "owner" in data && "name" in data && !isTargetGraphNameTouched) {
                  setValue(
                    "targetGraphName",
                    constructConsoleUrl({
                      pathname: `/${data.owner.accountName}/${data.name}/graphs/${query?.name}/${query?.version}`,
                    }),
                  );
                }
              }}
              renderInput={(params) => (
                <TextField {...(params as any)} error={!!error} helperText={error?.message} required />
              )}
              noOptionsText="No datasets found"
              isOptionEqualToValue={(option: FetchedDataset, value: FetchedDataset) => {
                return option.id === value.id;
              }}
              getOptionLabel={(option: FetchedDataset) => getDatasetLabel(option)}
              renderOption={(props, option: FetchedDataset) => {
                return (
                  <MenuItem {...props}>
                    <Avatar
                      size="sm"
                      className="mr-3"
                      avatarUrl={option.avatarUrl}
                      avatarName={option.displayName || option.name}
                      alt=""
                    />
                    <Highlight
                      fullText={getDatasetLabel(option)}
                      highlightedText={targetDatasetInputValue}
                      matcher={substringMatch}
                    />
                    <AccessLevelIcon level={option.accessLevel} size="xs" type="dataset" className="ml-3" />
                  </MenuItem>
                );
              }}
              onInputChange={(_event, newInputValue) => {
                searchDatasets("target", newInputValue);
                setTargetDatasetInputValue(newInputValue);
              }}
              {...rest}
            />
          )}
        />
      </FormField>

      <FormField label="Target graph name" className={getClassName("px-5 pb-5")} inputId={targetGraphNameLabelId}>
        <Controller
          name="targetGraphName"
          control={control}
          rules={{
            validate: validateIri,
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <TextField {...field} error={!!error} helperText={error?.message} id={targetGraphNameLabelId} fullWidth />
              <Alert className="mt-2" severity="warning">
                If the graph already exists in the dataset it will be overwritten.
              </Alert>
            </>
          )}
        />
      </FormField>

      <FormField
        label="Owner"
        className={getClassName("px-5 pb-5")}
        id={ownerLabelId}
        helperText="An account to save the query job under"
      >
        <Controller
          name="owner"
          control={control}
          rules={{
            required: "An owner is required.",
          }}
          render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
            <Select
              labelId={ownerLabelId}
              onChange={(event) => {
                onChange(event.target.value);
              }}
              {...rest}
              fullWidth
              inputProps={{ className: "flex center" }}
            >
              {accountOptions.map((account: Account) => (
                <MenuItem value={account.accountName} key={account.accountName} className="flex center">
                  <Avatar
                    size="sm"
                    className="mr-3"
                    avatarUrl={account.avatarUrl}
                    avatarName={account.accountName}
                    alt=""
                  />
                  <Highlight fullText={account.accountName} matcher={substringMatch} highlightedText={""} />
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormField>

      <div className="flex">
        <LoadingButton
          className="mt-2"
          type="submit"
          color="secondary"
          disabled={!formState.isValid}
          loading={formState.isSubmitting}
          aria-label="Create"
        >
          Create
        </LoadingButton>
        <Button onClick={onCancel} className="mt-2 ml-2" variant="text">
          Cancel
        </Button>
      </div>

      {formState.errors.root && (
        <Alert className="mt-2" severity="error">
          {formState.errors.root.message}
        </Alert>
      )}
      {error && (
        <Alert className="mt-2" severity="error">
          {error}
        </Alert>
      )}
    </form>
  );
};

export default QueryJobForm;
