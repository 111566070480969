import dedent from "dedent";
import * as React from "react";
import { factories } from "@triplydb/data-factory";
import fetch from "#helpers/fetch.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { getDescription } from "#reducers/resourceEditorDescriptions.ts";
import { Button, ConfirmationDialog, Dialog, FontAwesomeIcon } from "../../../components";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../../reducers/datasetManagement";
import { getGraphs } from "../../../reducers/graphs";
import InstanceForm from "../InstanceForm";
import formValuesToSparqlValues from "../InstanceForm/formValuesToSparqlValues";
import { MuiColor } from "../Process/processDefinitions";

const factory = factories.compliant;

const EditResource: React.FC<{ resource: string; color?: MuiColor; name: string; toStatus: string }> = ({
  resource,
  color,
  name,
  toStatus,
}) => {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const authorAcc = useAuthenticatedUser();
  const authorUrl = `${consoleUrl}/${authorAcc?.accountName}`;
  const dispatch = useDispatch();
  const removePrefixes = useRemovePrefixes();
  const onClose = () => {
    if (isDirty) {
      setConfirmationDialogOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        color={color === "default" ? undefined : color}
        elevation
        onClick={() => setOpen(true)}
        title="Edit instance"
        startIcon={<FontAwesomeIcon icon="pencil" />}
        size="small"
      >
        {name}
      </Button>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setOpen(false);
        }}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Are sure you want to close this form?"
        actionLabel="Close"
        description="If you close the form now, all changes will be lost."
      />

      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        title="Edit instance"
        closeButton
        disableEscapeKeyDown
      >
        <div className="px-5 pb-5">
          <InstanceForm
            onDirty={setDirty}
            editingResource={resource}
            onSubmit={async (values) => {
              const propertyValues = formValuesToSparqlValues(resource, values.properties, removePrefixes);

              const query = dedent`
                prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
                prefix meta: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>

                delete where {
                  <${resource}> ?p ?o
                };
                insert {
                  ?id meta:modifiedAt ?now;
                      meta:createdAt ?createdAt;
                      meta:creator ?creator.
                  ?subject ?predicate ?object
                } where {
                  bind(<${resource}> as ?id)
                  bind(now() as ?now)
                  optional {
                    ?id meta:createdAt ?createdAt;
                        meta:creator ?creator
                  }
                  values (?subject ?predicate ?object) {
                    (<${resource}> rdf:type <${values.type!.id}>)
                    (<${resource}> meta:status "${toStatus}")
                    (<${resource}> meta:modifiedBy <${factory.namedNode(authorUrl).id}>)
                    ${propertyValues.join("\n\t\t")}
                  }
                }`;

              const body = new FormData();
              body.set("update", query);

              const result = await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });
              if (result.status !== 200) {
                throw new Error("Something went wrong on the server...");
              }

              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                }),
              );
              await dispatch<typeof getDescription>(getDescription({ dataset: currentDs, resource: resource }));

              setOpen(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default EditResource;
