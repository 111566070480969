export interface EditorProcess {
  statuses: EditorInstanceStatus[];
  actions: EditorInstanceAction[];
}
export interface EditorInstanceStatus {
  name: string;
  color?: MuiColor;
}

export type EditorInstanceAction = InstanceDeleteAction | InstanceEditAction | InstanceEntryAction;
export type EditorActionType = EditorInstanceAction["type"];
export type MuiColor = "default" | "primary" | "secondary" | "error" | "success" | "warning" | "info";

interface EditorInstanceBaseAction {
  color?: MuiColor;
  name: string;
}
interface InstanceDeleteAction extends EditorInstanceBaseAction {
  type: "delete";
  onState: string[];
}
interface InstanceEditAction extends EditorInstanceBaseAction {
  type: "edit" | "updateStatus";
  onState: string[];
  toState: string;
  forceReview?: boolean;
}
interface InstanceEntryAction extends EditorInstanceBaseAction {
  type: "create";
  toState: string;
}

export const SIMPLE_PROCESS: EditorProcess = {
  statuses: [
    {
      name: "Instance",
      color: "primary",
    },
  ],
  actions: [
    {
      name: "Create",
      type: "create",
      toState: "Instance",
      color: "primary",
    },
    {
      name: "Edit",
      type: "edit",
      onState: ["Instance"],
      toState: "Instance",
      color: "secondary",
    },
    {
      name: "Delete",
      type: "delete",
      onState: ["Instance"],
      color: "error",
    },
  ],
};
export const COMPLEX_PROCESS: EditorProcess = {
  statuses: [
    {
      name: "Concept",
      color: "primary",
    },
    {
      name: "Staged",
      color: "warning",
    },
    {
      name: "Production",
      color: "success",
    },
    {
      name: "Deprecated",
      color: "default",
    },
  ],
  actions: [
    {
      name: "Create",
      type: "create",
      toState: "Concept",
      color: "primary",
    },
    {
      name: "Edit",
      type: "edit",
      onState: ["Concept"],
      toState: "Concept",
      color: "secondary",
    },
    {
      name: "Stage",
      type: "updateStatus",
      onState: ["Concept"],
      toState: "Staged",
      color: "warning",
    },
    {
      name: "Reject",
      type: "updateStatus",
      onState: ["Staged"],
      toState: "Concept",
      color: "warning",
    },
    {
      name: "Publish",
      type: "updateStatus",
      onState: ["Staged"],
      toState: "Production",
      color: "success",
      forceReview: true,
    },
    {
      name: "Revoke",
      type: "updateStatus",
      onState: ["Production"],
      toState: "Deprecated",
      color: "default",
    },
    {
      name: "Delete",
      type: "delete",
      onState: ["Concept"],
      color: "error",
    },
  ],
};
