import { Alert, Avatar, Chip, Container, Paper, Skeleton, Stack, Toolbar, Typography } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { stringifyQuery } from "@triply/utils-private";
import { HumanizedDate } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.js";
import EditResource from "../Actions/EditResource";
import RemoveResource from "../Actions/RemoveResource";
import ResourceAction from "../Actions/ResourceAction";
import { AUTHOR_IRI, CREATED_AT_IRI, EDITOR_NOTE_IRI, MODIFIED_AT_IRI, MODIFIED_BY_IRI, STATUS_IRI } from "../helpers";
import { useEditorProcessContext } from "../Process";
import PropertiesViewer from "./viewers/PropertiesViewer";

interface Props {
  dsId?: string;
  resource: string;
  className?: string;
  innerClassName?: string;
}

const InstanceView: React.FC<Props> = ({ dsId, resource, className, innerClassName }) => {
  const acl = useAcl();
  const account = useCurrentAccount();
  const currentDs = useCurrentDataset();

  const resourceDescription = useSelector((state: GlobalState) =>
    dsId ? state.resourceEditorDescriptions[dsId]?.resources[resource] : undefined,
  );
  const applyPrefixes = useApplyPrefixes();
  const { getStatusConfigFor } = useEditorProcessContext();
  const authenticatedUser = useAuthenticatedUser();
  const consoleUrl = useConstructConsoleUrl()();
  const userIri = `${consoleUrl}/${authenticatedUser?.accountName}`;
  if (!resourceDescription) return <Skeleton>Loading</Skeleton>;
  const resourceStatus = resourceDescription?.properties?.[STATUS_IRI]?.[0]?.value;
  const authorIri = resourceDescription?.properties?.[AUTHOR_IRI]?.[0]?.value;
  const createdDate = resourceDescription?.properties?.[CREATED_AT_IRI]?.[0]?.value;
  const modifiedDate = resourceDescription?.properties?.[MODIFIED_AT_IRI]?.[0]?.value;
  const editorNote = resourceDescription?.properties?.[EDITOR_NOTE_IRI]?.[0]?.value;
  const modifiedByIri = resourceDescription?.properties?.[MODIFIED_BY_IRI]?.[0]?.value;
  const authenticatedUserRole = acl.getRoleInAccount(account);

  const config = getStatusConfigFor(resourceStatus);

  return (
    <Paper className={getClassName("p-5", className)}>
      <Container className={getClassName("flex column g-5", innerClassName)}>
        {!resourceDescription ? null : (
          <Stack gap={1} direction={"row"} alignItems={"flex-start"} flexWrap="wrap">
            <div className="my-2">
              <Typography variant="h4" component={"h1"}>
                {resourceDescription?.valueLabel || applyPrefixes(resourceDescription.value)}
              </Typography>
              <a href={resourceDescription.value}>{applyPrefixes(resourceDescription.value)}</a>{" "}
              <Chip
                label={resourceDescription.typeLabel || applyPrefixes(resourceDescription.type)}
                component={Link}
                to={{
                  pathname: `/${currentDs!.owner.accountName}/${currentDs!.name}/data-model`,
                  search: stringifyQuery({ resource: resourceDescription.type }),
                }}
              />
            </div>
            <div className="grow" />
            <div className="flex g-2">
              {config?.actions.map((action) => {
                switch (action.type) {
                  case "create":
                    // Create actions should be handled in the Toolbar
                    return null;
                  case "delete":
                    return <RemoveResource color={action.color} name={action.name} key={action.name + action.type} />;
                  case "edit":
                    return (
                      <EditResource
                        key={action.name + action.type}
                        resource={resource}
                        color={action.color}
                        name={action.name}
                        toStatus={action.toState}
                      />
                    );
                  case "updateStatus":
                    return (
                      <ResourceAction
                        key={action.name + action.type}
                        buttonLabel={action.name}
                        color={action.color === "default" ? undefined : action.color}
                        confirmationLabel="Update status"
                        newStatus={action.toState}
                        title={action.name}
                        disabled={
                          action.forceReview && authorIri === userIri && authenticatedUserRole !== "owner"
                            ? "You are not allowed to do this action as you are the author."
                            : undefined
                        }
                      />
                    );
                }
              })}
            </div>
          </Stack>
        )}
        {resourceStatus && (
          <Toolbar className="flex wrap center g-3" component={(props) => <Paper {...props} variant="outlined" />}>
            <div className="flex g-3">
              {config?.status?.name && (
                <Stack>
                  <Typography variant="caption">Status</Typography>
                  <Chip
                    label={config?.status?.name || "unknown"}
                    color={config?.status?.color === "default" ? undefined : config?.status?.color}
                  />
                </Stack>
              )}
              {modifiedByIri && (
                <Stack>
                  <Typography variant="caption">Modified by</Typography>
                  <Chip
                    label={modifiedByIri.split("/").pop()}
                    avatar={<Avatar>{modifiedByIri.split("/").pop()?.[0] || "?"}</Avatar>}
                  />
                </Stack>
              )}
              {modifiedDate && (
                <Stack>
                  <Typography variant="caption">Modified</Typography>
                  <HumanizedDate date={modifiedDate} />
                </Stack>
              )}
              {authorIri && (
                <Stack>
                  <Typography variant="caption">Author</Typography>
                  <Chip
                    label={authorIri.split("/").pop()}
                    avatar={<Avatar>{authorIri.split("/").pop()?.[0] || "?"}</Avatar>}
                  />
                </Stack>
              )}
              {createdDate && (
                <Stack>
                  <Typography variant="caption">Created</Typography>
                  <HumanizedDate date={createdDate} />
                </Stack>
              )}
            </div>
          </Toolbar>
        )}
        {editorNote && (
          <Alert severity="info" className="my-5">
            {editorNote}
          </Alert>
        )}
        {dsId && resourceDescription && <PropertiesViewer dsId={dsId} description={resourceDescription} />}
      </Container>
    </Paper>
  );
};

export default InstanceView;
