import { Button } from "@mui/material";
import dedent from "dedent";
import * as React from "react";
import { useHistory } from "react-router";
import { factories } from "@triplydb/data-factory";
import fetch from "#helpers/fetch.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { ConfirmationDialog, Dialog, FontAwesomeIcon } from "../../../components";
import useConstructConsoleUrl from "../../../helpers/hooks/useConstructConsoleUrl";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../../helpers/hooks/useDispatch";
import { stringifyQuery } from "../../../helpers/utils";
import { refreshDatasetsInfo, useCurrentDataset } from "../../../reducers/datasetManagement";
import { getGraphs } from "../../../reducers/graphs";
import InstanceForm from "../InstanceForm";
import formValuesToSparqlValues from "../InstanceForm/formValuesToSparqlValues";
import { MuiColor } from "../Process/processDefinitions";

const factory = factories.compliant;

interface Props {
  className?: string;
  toStatus: string;
  color?: MuiColor;
  name: string;
}

const CreateResource: React.FC<Props> = ({ className, color, toStatus, name }) => {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const authorAcc = useAuthenticatedUser();
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const authorUrl = `${consoleUrl}/${authorAcc?.accountName}`;
  const history = useHistory();
  const dispatch = useDispatch();
  const removePrefixes = useRemovePrefixes();
  const onClose = () => {
    if (isDirty) {
      setConfirmationDialogOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        className={className}
        endIcon={<FontAwesomeIcon icon="plus" />}
        color={color === "default" ? undefined : color}
        onClick={() => setOpen(true)}
        title="Create a new instance in the dataset"
      >
        {name}
      </Button>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setOpen(false);
        }}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Are sure you want to close this form?"
        actionLabel="Close"
        description="If you close the form now all changes will be lost"
      />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        title="Create a new instance"
        closeButton
        disableEscapeKeyDown
      >
        <div className="px-5 pb-5">
          <InstanceForm
            onDirty={setDirty}
            onSubmit={async (values) => {
              const id = factory.namedNode(removePrefixes(values.iri.trim())).id;

              const propertyValues = formValuesToSparqlValues(id, values.properties, removePrefixes);

              const query = dedent`
                prefix meta: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>

                insert {
                  ?id a <${values.type!.id}>;
                      meta:status "${toStatus}";
                      meta:creator <${factory.namedNode(authorUrl).id}>;
                      meta:createdAt ?now .
                  ?subject ?predicate ?object .
                } where {
                  bind(<${id}> as ?id)
                  bind(now() as ?now)
                  optional {
                    values (?subject ?predicate ?object) {
                      ${propertyValues.join("\n\t\t")}
                    }
                  }
                }`;

              const body = new FormData();
              body.set("update", query);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                }),
              );

              setOpen(false);

              history.push({
                search: stringifyQuery({ resource: id }),
              });
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default CreateResource;
