import { Alert, Skeleton } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeButton, FormField } from "#components/index.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import Editor from "./Editors/Editor";
import { FormValues, Property } from "./Types";
import * as styles from "./style.scss";

export interface PropertyModel {
  nodeKind?: "IRI" | "BlankNode" | "Literal";
  datatype?: string;
  type?: string;
  defaultValue?: string;
  required?: boolean;
}

function getDefaultValue(propertyModel: PropertyModel, wellknownBaseIri: string): Property {
  switch (propertyModel.nodeKind || "Literal") {
    case "IRI":
      return {
        value: propertyModel.defaultValue || "",
        nodeKind: "IRI",
        label: "",
        description: "",
      };
    case "BlankNode":
      return {
        nodeKind: "BlankNode",
        type: propertyModel.type || "",
        value: propertyModel.defaultValue || `${wellknownBaseIri}/${uuidv4()}`,
        properties: {},
      };
    case "Literal":
      return {
        value: propertyModel.defaultValue || "",
        datatype: propertyModel.datatype || "",
        nodeKind: "Literal",
        language: "",
      };
  }
}
const PropertyShape: React.FC<{ propertyShape?: string; propertyPath: string; name: `properties.${string}` }> = ({
  propertyShape,
  propertyPath,
  name,
}) => {
  const { data, error, loading } = useSparql<
    Array<{
      name: string;
      description: string;
      path: string;
      datatype: string;
      type: string;
      nodeKind: "Literal" | "IRI" | "BlankNode";
      defaultValue: string;
      minCount: string;
      maxCount: string;
    }>
  >(
    !!propertyShape &&
      `
    prefix sh: <http://www.w3.org/ns/shacl#>
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>

    select
      *
    where {
      bind(<${propertyShape}> as ?propertyShape)
      ?propertyShape sh:path ?path .
      optional {
        ?propertyShape sh:name ?name .
      }
      optional {
        ?path rdfs:label ?name .
      }
      optional {
        ?propertyShape sh:description ?description
      }
      optional {
        ?path rdfs:comment ?description
      }
      optional {
        ?propertyShape sh:datatype ?datatype .
      }
      optional {
        ?propertyShape sh:class ?type .
        optional {
          ?nestedNodeShape sh:targetClass ?type ;
             sh:nodeKind sh:BlankNode .
        }
      }
      bind(if(bound(?type), if(bound(?nestedNodeShape), "BlankNode", "IRI"), "Literal") as ?nodeKind)
      optional {
        ?propertyShape sh:minCount ?minCount .
      }
      optional {
        ?propertyShape sh:maxCount ?maxCount .
      }
      optional {
        ?propertyShape sh:defaultValue ?defaultValue .
      }
    }
    limit 1
    `,
  );

  const applyPrefixes = useApplyPrefixes();
  const wellknownBaseIri = useConstructConsoleUrl()({ pathname: `/.well-known/genid` });

  const { control, getFieldState } = useFormContext<FormValues>();

  const minCount = Number(data?.[0]?.minCount) || 0;
  const maxCount = Number(data?.[0]?.maxCount) || Number.MAX_SAFE_INTEGER;

  const label = data?.[0]?.name || applyPrefixes(propertyPath);

  const { fields, remove, append } = useFieldArray<FormValues>({
    name: name,
    control,
    rules: {
      validate: (value) => {
        const nonEmptyValues = value.filter((val) => val?.value);
        const count = nonEmptyValues.length || 0;
        if (minCount === 1 && count === 0) {
          return `A value for '${label}' is required.`;
        }
        if (count < minCount) {
          return `${minCount} values are required for '${label}'.`;
        }
        if (maxCount === 1 && count > maxCount) {
          return `Only one value is allowed for '${label}'.`;
        }
        if (count > maxCount) {
          return `Not more than ${maxCount} values are allowed for '${label}'.`;
        }
        return undefined;
      },
    },
  });

  if (loading) return <Skeleton />;

  if (error) return <Alert severity="error">Failed to load.</Alert>;

  if (propertyShape && (!data || !data[0])) return null;

  const count = fields.length;

  const errors = getFieldState(name).error;
  const errorMessage = errors?.root?.message;
  const containsError = !!errorMessage || (errors as any)?.length > 0;

  const propertyModel = {
    nodeKind: data?.[0]?.nodeKind,
    datatype: data?.[0]?.datatype,
    type: data?.[0]?.type,
    defaultValue: data?.[0]?.defaultValue,
  };

  const required = minCount > 0;

  return (
    <FormField
      className={getClassName(styles.formField, { [styles.error]: containsError })}
      label={label}
      helperText={data?.[0]?.description}
      required={required}
      breakPoint={1}
    >
      <div className="flex column g-7">
        <div className="flex g-7">
          <div className="flex column g-7 grow">
            {fields.map((field, index) => {
              return (
                <div key={field.id} className="flex g-5">
                  <Editor name={`${name}.${index}`} propertyModel={propertyModel} />
                  <div className="flex center">
                    <FontAwesomeButton color="error" icon="trash" onClick={() => remove(index)} title="Remove value" />
                  </div>
                </div>
              );
            })}
          </div>
          {propertyShape && count < maxCount && (
            <div className={styles.appendField}>
              <FontAwesomeButton
                icon="plus"
                onClick={() =>
                  append(getDefaultValue(propertyModel, wellknownBaseIri), { focusName: `${name}.${fields.length}` })
                }
                title={`Add new value for '${label}'`}
              />
            </div>
          )}
        </div>

        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </div>
    </FormField>
  );
};

export default PropertyShape;
