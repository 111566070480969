import dedent from "dedent";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { Button, ConfirmationDialog, FontAwesomeIcon } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { refreshDatasetsInfo, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { getGraphs } from "#reducers/graphs.ts";
import { removeEditorDescription } from "#reducers/resourceEditorDescriptions.ts";
import { MuiColor } from "../Process/processDefinitions";

const RemoveResource: React.FC<{
  color?: MuiColor;
  name: string;
}> = ({ color, name }) => {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const currentDs = useCurrentDataset()!;

  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const dispatch = useDispatch();
  const resource = parseSearchString(useLocation().search).resource as string;
  const history = useHistory();

  return (
    <>
      <Button
        color={color === "default" ? undefined : color}
        elevation
        onClick={() => setOpen(true)}
        title="Remove this instance from the dataset"
        startIcon={<FontAwesomeIcon icon="trash" />}
        size="small"
      >
        {name}
      </Button>
      <ConfirmationDialog
        open={open}
        loading={saving}
        onClose={() => setOpen(false)}
        actionLabel="Remove instance"
        title="Remove this instance?"
        onConfirm={async () => {
          setSaving(true);

          const query = dedent`
                delete {
                  ?id ?p ?o.
                  ?x ?y ?id.
                }  where {
                  optional {
                    ?id ?p ?o.
                  }
                  optional {
                    ?x ?y ?id.
                  }
                  filter (?id = <${resource}>)
                }`;

          const body = new FormData();
          body.set("update", query);

          await fetch(updateUrl, {
            credentials: "same-origin",
            method: "POST",
            body: body,
          });

          await dispatch<typeof refreshDatasetsInfo>(
            refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
          );
          await dispatch<typeof getGraphs>(
            getGraphs({
              accountName: currentDs.owner.accountName,
              datasetName: currentDs.name,
              datasetId: currentDs.id,
            }),
          );

          await dispatch<typeof removeEditorDescription>(removeEditorDescription(currentDs, resource));

          setSaving(false);
          setOpen(false);

          history.push({
            search: stringifyQuery({ resource: undefined }),
          });
        }}
        description={`Are you sure you want to remove '${resource}' from the dataset?`}
      />
    </>
  );
};

export default RemoveResource;
