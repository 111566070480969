import { Autocomplete, Chip, debounce, ListItem, ListItemText, TextField } from "@mui/material";
import getClassName from "classnames";
import dedent from "dedent";
import * as React from "react";
import { useHistory } from "react-router";
import { stringifyQuery } from "@triply/utils-private";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "./InstanceForm/useSparql";
import * as styles from "./style.scss";

interface SearchResult {
  iri: string;
  label?: string;
  class?: string;
}

function getQuery(search: string) {
  return dedent`
    #! optimize: false # this query is slightly faster for UWV when we turn off optimization (2024-10-11)
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix skos: <http://www.w3.org/2004/02/skos/core#>
    prefix sh: <http://www.w3.org/ns/shacl#>

    select ?resource (sample(?_label) as ?label) (sample(?_classLabel) as ?classLabel) {
      # find a small number of hits
      {
        select * {
          {
            # first generate highest quality results
            ?resource rdfs:label|skos:prefLabel ?_label .
            filter(contains(lcase(?_label),lcase("${search}")))
          } union {
            ?resource rdfs:label|skos:prefLabel ?_label .
            filter(contains(lcase(str(?resource)),lcase("${search}")))
          } union {
            ?resource a []
            filter(contains(lcase(str(?resource)),lcase("${search}")))
          }
          ?resource a ?targetClass .
          [] sh:targetClass ?targetClass.
        } limit 20
      }

      # enrich the results
      optional {
        ?targetClass rdfs:label ?_classLabel
      }
    } group by ?resource
    `;
}

const FindResource: React.FC<{}> = ({}) => {
  const sparql = useSparql();
  const { push } = useHistory();
  const [searchString, setSearchString] = React.useState("");
  const [options, setOptions] = React.useState<readonly SearchResult[]>([]);
  const applyPrefixes = useApplyPrefixes();
  const resource = useCurrentResource();

  const debouncedQuery = React.useMemo(
    () =>
      debounce(
        (
          { searchTerm, abortSignal }: { searchTerm: string; abortSignal: AbortSignal },
          callback: (results?: readonly SearchResult[]) => void,
        ) => {
          sparql(getQuery(searchTerm), abortSignal)
            .then((results) => {
              callback(
                results.results.bindings.map((binding) => {
                  return {
                    iri: binding["resource"]!.value,
                    label: binding["label"]?.value,
                    class: binding["classLabel"]?.value,
                  };
                }),
              );
            })
            .catch(() => {});
        },
        400,
      ),
    [sparql],
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    let active = true;
    debouncedQuery({ searchTerm: searchString, abortSignal: abortController.signal }, (results) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
      abortController.abort("Not needed anymore");
    };
  }, [debouncedQuery, searchString]);

  return (
    <Autocomplete<SearchResult>
      size="small"
      className={getClassName(styles.instanceSearch)}
      componentsProps={{
        popper: {
          style: { width: "fit-content" },
        },
      }}
      renderInput={(props) => {
        return (
          <TextField
            {...(props as any)}
            label="Find"
            fullWidth
            variant="outlined"
            placeholder={applyPrefixes(resource)}
            InputLabelProps={{ ...props.InputLabelProps, shrink: true }}
          />
        );
      }}
      filterOptions={(x) => x}
      options={options}
      onInputChange={(_event, newInputValue) => {
        setSearchString(newInputValue.replace(/"/g, ""));
      }}
      onChange={(_event, newValue) => {
        if (!newValue) return;
        push({
          search: stringifyQuery({ resource: newValue.iri }),
        });
      }}
      getOptionLabel={(option) => option.label || option.iri}
      getOptionKey={(option) => option.iri}
      noOptionsText={searchString === "" ? "No instances found according to the data model" : "No instances found"}
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemText
              primary={
                <span>
                  {option.label || applyPrefixes(option.iri)}
                  {option.class && <Chip label={option.class} size="small" className="ml-2" />}
                </span>
              }
              secondary={option.label ? applyPrefixes(option.iri) : undefined}
            />
          </ListItem>
        );
      }}
    />
  );
};

export default FindResource;
