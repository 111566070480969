const META_PREFIX = "https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/";
export const STATUS_IRI = META_PREFIX + "status";
export const CREATED_AT_IRI = META_PREFIX + "createdAt";
export const MODIFIED_AT_IRI = META_PREFIX + "modifiedAt";
export const MODIFIED_BY_IRI = META_PREFIX + "modifiedBy";
export const AUTHOR_IRI = META_PREFIX + "creator";
export const EDITOR_NOTE_IRI = META_PREFIX + "editorialNote";

export const IGNORED_EDITOR_PREDICATE_IRIS = [
  STATUS_IRI,
  CREATED_AT_IRI,
  AUTHOR_IRI,
  MODIFIED_AT_IRI,
  EDITOR_NOTE_IRI,
  MODIFIED_BY_IRI,
];
