import { ContextDefinition } from "jsonld";
import { Frame } from "jsonld/jsonld-spec.js";
import * as Models from "./Models.js";

export import prefixes = ___prefixes;

export import me = account;
import { Readable, Stream } from "stream";
import { DatasetLicenseId } from "./Constants.js";

type Empty = { [key: string | number | symbol]: never };

export interface RequestTemplate {
  Query: {};
  Params: {};
  Body: any;
}

export interface ResponseTemplate {
  Body: any;
}

export interface HttpMethodTemplate {
  Req: RequestTemplate;
  Res: ResponseTemplate;
}

export type SavedQueryArguments = {
  [queryArgument: string]: string;
};

export type AllParams = info.Params &
  imgs.Params &
  imgs.avatars.a._accountId.Params &
  imgs.avatars.d._datasetId.Params &
  imgs.logos.Params &
  admin.Params &
  admin.services._serviceId.Params &
  ___prefixes.Params &
  ___prefixes._prefix.Params &
  datasets._account.Params &
  datasets._account._dataset.Params &
  datasets._account._dataset.services._serviceName.Params &
  datasets._account._dataset.graphs._graphId.Params &
  datasets._account._dataset.hooks._hookId.Params &
  datasets._account._dataset.assets._assetId.Params &
  datasets._account._dataset.assets._assetId._versionId.Params &
  datasets._account._dataset.download.Params &
  datasets._account._dataset.jobs._jobId.Params &
  datasets._account._dataset.jobs._jobId._sourceFileId.Params &
  queries._account.Params &
  queries._account._query.Params &
  queries._account._query.run.Params &
  stories._account.Params &
  stories._account._story.Params &
  topics._topicId.Params &
  account.members._member.Params &
  account.tokens._tokenId.Params &
  s._short.Params;
export interface GenericQuery {
  fields?: string;
}
export namespace info {
  export namespace frontpage {
    export interface Params {}
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Models.FrontPage;
      };
    }
    export interface Patch {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Models.FrontpageUpdate;
      };
      Res: {
        Body: Models.FrontPage;
      };
    }
  }
  export interface Params {}
  export interface Get {
    Req: {
      Query: GenericQuery;
      Params: Params;
      Body: Empty;
    };
    Res: {
      Body: Models.ClientConfig;
    };
  }
  export interface Patch {
    Req: {
      Query: GenericQuery;
      Params: Params;
      Body: Models.ClientConfigUpdate;
    };
    Res: {
      Body: Models.ClientConfig;
    };
  }
}

export namespace imgs {
  export interface Params {}
  export interface Get {
    Req: {
      Query: GenericQuery & {
        v?: number;
        w?: number;
        h?: number;
      };
      Params: Params;
      Body: Empty;
    };
    Res: {
      Body: Buffer;
    };
  }

  export namespace avatars {
    export namespace a {
      export namespace _accountId {
        export interface Params {
          accountId: string;
        }
        export interface Get {
          Req: {
            Query: GenericQuery & {
              v?: number;
            };
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Empty;
          };
        }
        export interface Post {
          Req: {
            Query: GenericQuery & {
              verbose?: string;
            };
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.Account;
          };
        }
      }
    }
    export namespace d {
      export namespace _datasetId {
        export interface Params {
          datasetId: string;
        }
        export interface Get {
          Req: {
            Query: GenericQuery & {
              v?: number;
            };
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Empty;
          };
        }
        export interface Post {
          Req: {
            Query: GenericQuery;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.Dataset;
          };
        }
      }
    }
  }

  export namespace logos {
    export interface Params {}
    export interface Get {
      Req: {
        Query: {
          v?: number;
          w?: number;
          h?: number;
        };
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Buffer;
      };
    }
    export interface Post {
      Req: {
        Query: Empty;
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Models.ClientConfig;
      };
    }
    export interface Delete {
      Req: {
        Query: Empty;
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Models.ClientConfig;
      };
    }
  }
}

export namespace admin {
  export interface Params {}
  export namespace cache {
    export interface Post {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Models.AdminCacheUpdate;
      };
      Res: {
        Body: Models.ClientConfig;
      };
    }
  }
  export namespace debug {
    export interface Post {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Models.AdminDebugUpdate;
      };
      Res: {
        Body: Empty;
      };
    }
  }
  export namespace indexJobs {
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Models.Dataset[];
      };
    }
  }
  export namespace limits {
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Models.InstanceLimitsJson;
      };
    }
  }
  export namespace speedyStats {
    export interface Get {
      Req: {
        Query: GenericQuery & { zoom: number };
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Models.SpeedyStats;
      };
    }
  }
  export namespace tasks {
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Models.BackgroundTask[];
      };
    }
    export namespace _id {
      export interface Params {
        id: string;
      }
      export interface Post {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Models.BackgroundTaskAction;
        };
        Res: {
          Body: Models.BackgroundTask;
        };
      }
    }
  }
  export namespace redirects {
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Models.RedirectRule[];
      };
    }
    export interface Put {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Models.RedirectRule[];
      };
      Res: {
        Body: Models.RedirectRule[];
      };
    }
  }
  export namespace services {
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Array<Models.ServiceMetadata>;
      };
    }
    export namespace _serviceId {
      export interface Params {
        serviceId: string;
      }
      export interface Get {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Models.ServiceMetadata;
        };
      }
      export interface Delete {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Empty;
        };
      }
    }
  }

  export namespace queries {
    export interface Get {
      Req: {
        Query: GenericQuery & {
          q?: string;
        };
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: Models.AdminQueries;
      };
    }
  }

  export namespace jobs {
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: Array<Models.AdminPipelineJob>;
      };
    }
  }
}

//Used in root path, and in /datasets/<u>/<d>/
export namespace ___prefixes {
  export interface Params {}
  export interface Get {
    Req: {
      Query: GenericQuery & {
        scope?: "local" | "global";
      };
      Params: Params;
      Body: Empty;
    };
    Res: {
      Body: Models.Prefixes;
    };
  }

  export interface Patch {
    Req: {
      Query: GenericQuery;
      Params: Params;
      Body: Models.PrefixUpdate[];
    };
    Res: {
      Body: Models.Prefixes;
    };
  }

  export interface Put {
    Req: {
      Query: GenericQuery;
      Params: Params;
      Body: Models.PrefixUpdate[];
    };
    Res: {
      Body: Models.Prefixes;
    };
  }
  export interface Post {
    Req: {
      Query: GenericQuery;
      Params: Params;
      Body: Models.PrefixUpdate;
    };
    Res: {
      Body: Models.Prefix;
    };
  }

  export namespace _prefix {
    export interface Params {
      prefix: string;
    }
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Models.Prefix;
      };
    }
    export interface Put {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: string;
      };
      Res: {
        Body: Models.Prefix;
      };
    }
    export interface Delete {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: string;
      };
    }
  }
}

export namespace datasets {
  interface PaginatedDatasetQuery {
    since?: string;
    limit?: number;
    sortDirection?: "asc" | "desc";
    sparql?: string;
    q?: string;
  }
  export interface Get<Verbose = false> {
    Req: {
      Query: Verbose extends true
        ? GenericQuery &
            PaginatedDatasetQuery & {
              verbose: ""; //We should be able to pass it as `?verbose`
            }
        : GenericQuery & PaginatedDatasetQuery;
      Params: Empty;
      Body: Empty;
    };
    Res: {
      Body: Verbose extends true ? Models.DatasetVerbose[] : Models.Dataset[];
    };
  }
  export namespace _account {
    export interface Params {
      account: string;
    }
    export interface Get {
      Req: {
        Query: GenericQuery & {
          since?: string;
          accessLevel?: "public" | "internal" | "private";
          sortBy?: "createdAt" | "updatedAt" | "name";
          sortDirection?: "asc" | "desc";
        };
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Models.Dataset[];
      };
    }
    export interface Post {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Models.NewDataset;
      };
      Res: {
        Body: Models.Dataset;
      };
    }

    export namespace _dataset {
      export import prefixes = ___prefixes;
      export interface Params extends datasets._account.Params {
        dataset: string;
      }
      export interface Get<Verbose = false> {
        Req: {
          Query: Verbose extends true
            ? GenericQuery & {
                verbose: null; //Set to null, because we should be able to pass it as `?verbose`
              }
            : GenericQuery;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Verbose extends true ? Models.DatasetVerbose : Models.Dataset;
        };
      }
      export interface Post {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Models.NewDataset;
        };
        Res: {
          Body: Models.Dataset;
        };
      }
      export interface Patch {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Models.UpdateDataset;
        };
        Res: {
          Body: Models.Dataset;
        };
      }
      export interface Delete {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Empty;
        };
      }
      export namespace copy {
        export interface Post {
          Req: {
            Query: GenericQuery;
            Params: Params;
            Body: Models.NewDataset;
          };
          Res: {
            Body: Models.Dataset;
          };
        }
      }
      export namespace chown {
        export interface Post {
          Req: {
            Query: GenericQuery;
            Params: Params;
            Body: Models.NewDataset;
          };
          Res: {
            Body: Models.Dataset;
          };
        }
      }
      export namespace services {
        export interface Post {
          Req: {
            Query: GenericQuery;
            Params: Params;
            Body: Models.CreateService;
          };
          Res: {
            Body: Models.ServiceMetadata;
          };
        }
        export interface Get {
          Req: {
            Query: GenericQuery;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Array<Models.ServiceMetadata>;
          };
        }

        export namespace _serviceName {
          export interface Params extends datasets._account._dataset.Params {
            serviceName: string;
            ext: string | undefined;
          }

          export interface ElasticsearchParams extends Params {
            api: Models.ElasticServiceApi;
          }
          export namespace graphs {
            export interface Get {
              Req: {
                Query: {
                  page?: number;
                };
                Params: Params;
                Body: Empty;
              };
              Res: {
                Body: Array<Models.ServiceGraphInfo>;
              };
            }
          }
          export namespace sparql {
            export interface Get {
              Req: {
                Query: Models.SparqlQuery;
                Params: Params;
                Body: Models.SparqlQuery;
              };
              Res: {
                Body: any;
              };
            }
            export interface Post {
              Req: {
                Query: Models.SparqlQuery;
                Params: Params;
                Body: Models.SparqlQuery;
              };
              Res: {
                Body: any;
              };
            }
          }
          export namespace logs {
            export interface Get {
              Req: {
                Query: { tail: number };
                Params: Params;
                Body: Empty;
              };
              Res: {
                Body: Models.PodLogs;
              };
            }
          }
          export namespace mappings {
            export interface Get {
              Req: {
                Query: Empty;
                Params: Params;
                Body: Empty;
              };
              Res: {
                Body: any;
              };
            }
          }
          export namespace search {
            export interface Get {
              Req: {
                Query: Models.SimpleSearchQuery;
                Params: ElasticsearchParams;
                Body: Empty;
              };
              Res: {
                Body: any;
              };
            }
          }
          export namespace elasticsearch {
            export interface Post {
              Req: {
                Query: GenericQuery;
                Params: ElasticsearchParams;
                Body: Models.ElasticsearchQuery;
              };
              Res: {
                Body: any;
              };
            }
          }
          export interface Get {
            Req: {
              Query: GenericQuery;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: Models.ServiceMetadata;
            };
          }

          export interface Delete {
            Req: {
              Query: GenericQuery;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: undefined;
            };
          }
          export interface Post {
            Req: {
              Query: GenericQuery;
              Params: Params;
              Body: Models.ServiceActions;
            };
            Res: {
              Body: Models.ServiceMetadata;
            };
          }
          export interface Patch {
            Req: {
              Query: GenericQuery;
              Params: Params;
              Body: Models.UpdateService;
            };
            Res: {
              Body: Models.ServiceMetadata;
            };
          }
        }
      }
      export namespace sparql {
        export interface Params extends datasets._account._dataset.Params {
          ext: string | undefined;
        }
        export interface Get {
          Req: {
            Query: Models.SparqlQuery;
            Params: Params;
            Body: Models.SparqlQuery;
          };
          Res: {
            Body: any;
          };
        }
        export interface Post {
          Req: {
            Query: Models.SparqlQuery;
            Params: Params;
            Body: Models.SparqlQuery;
          };
          Res: {
            Body: any;
          };
        }
      }
      export namespace graphs {
        export interface Get {
          Req: {
            Query: GenericQuery & {
              since?: string;
              sortBy?: "graphName";
              sortDirection?: "asc" | "desc";
            };
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.Graphs;
          };
        }
        export interface Delete {
          Req: {
            Query: GenericQuery;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.Graphs;
          };
        }

        export namespace _graphId {
          export interface Params extends datasets._account._dataset.Params {
            graphId: string;
          }
          export interface Get {
            Req: {
              Query: GenericQuery;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: Models.Graph;
            };
          }

          export interface Patch {
            Req: {
              Query: GenericQuery;
              Params: Params;
              Body: Partial<Models.Graph>;
            };
            Res: {
              Body: Models.Graph;
            };
          }

          export interface Delete {
            Req: {
              Query: Empty;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: Empty;
            };
          }

          export namespace importQualityReport {
            export interface Post {
              Req: {
                Query: GenericQuery;
                Params: Params;
                Body: { graphName: string };
              };
              Res: {
                Body: Models.Graph;
              };
            }
          }
        }
      }

      export namespace insights {
        export namespace classFrequency {
          export interface Get {
            Req: {
              Query: {
                graphName: string;
                offset?: number;
              } & GenericQuery;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: Models.ClassFrequency;
            };
          }
        }

        export namespace classHierarchy {
          export interface Get {
            Req: {
              Query: GenericQuery;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: Models.ClassHierarchy;
            };
          }
        }
      }

      /////////////////////////////////////////////////
      export namespace hooks {
        export interface Get {
          // get all hooks

          Req: {
            Query: GenericQuery;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.Hooks;
          };
        }

        export interface Post {
          // post single hook
          Req: {
            Query: GenericQuery;
            Params: Params;
            Body: Models.HookCreate;
          };
          Res: {
            Body: Models.Hook;
          };
        }

        export namespace _hookId {
          export interface Params extends datasets._account._dataset.Params {
            hookId: string;
          }
          // get single hook
          export interface Get {
            Req: {
              Query: GenericQuery;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: Models.Hook;
            };
          }

          export interface Patch {
            // update single hook
            Req: {
              Query: GenericQuery;
              Params: Params;
              Body: Models.Hook;
            };
            Res: {
              Body: Models.Hook;
            };
          }

          export interface Delete {
            // delete single hook
            Req: {
              Query: Empty;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: Empty;
            };
          }
        }
      }

      export namespace hookRecords {
        export namespace _hookId {
          export interface Params extends datasets._account._dataset.Params {
            hookId: string;
          }
          export interface Get {
            Req: {
              Query: GenericQuery & {
                offset: number;
                limit: number;
              };
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: {
                records: Models.WebHookTriggerRecords;
                canLoadMore: boolean;
              };
            };
          }
        }
      }
      export namespace graphql {
        export interface Get {
          Req: {
            Query: Omit<Models.GraphQLParams, "variables">;
            //Parameters for the account and dataset names
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.GraphQLResponse;
          };
        }
        export interface Post {
          Req: {
            Query: Empty;
            //Parameters for the account and dataset names
            Params: Params;
            Body: Models.GraphQLParams;
          };
          Res: {
            Body: Models.GraphQLResponse;
          };
        }
        export namespace status {
          export interface Get {
            Req: {
              Query: Empty;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: Models.GraphQLStatus;
            };
          }
        }
      }
      export namespace assets {
        export interface Get {
          Req: {
            Query: GenericQuery & {
              since?: string;
              sortBy?: "assetName";
              sortDirection?: "asc" | "desc";
              fileName?: string;
            };
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.Assets | Models.Asset;
          };
        }

        export interface Post {
          Req: {
            Query: Empty;
            Params: Params & { assetId?: string };
            Body: Empty;
          };
          Res: {
            Body: Models.Asset;
          };
        }

        export interface Delete {
          Req: {
            Query: Empty;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Empty;
          };
        }

        export namespace download {
          export interface Get {
            Req: {
              Query: {
                fileName: string;
              };
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: any;
            };
          }
        }
        export namespace _assetId {
          export interface Params extends datasets._account._dataset.Params {
            assetId: string;
          }
          export interface Get {
            Req: {
              Query: GenericQuery;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: Models.Asset;
            };
          }

          export interface Put {
            Req: {
              Query: Empty;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: Models.Assets;
            };
          }

          export interface Delete {
            Req: {
              Query: Empty;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: Empty;
            };
          }

          export namespace _versionId {
            export interface Params extends datasets._account._dataset.assets._assetId.Params {
              versionId: string;
            }
            export interface Get {
              Req: {
                Query: GenericQuery;
                Params: Params;
                Body: Empty;
              };
              Res: {
                Body: any;
              };
            }

            export interface Delete {
              Req: {
                Query: Empty;
                Params: Params;
                Body: Empty;
              };
              Res: {
                Body: Models.Asset;
              };
            }
          }
        }
      }

      export namespace imports {
        export interface Get {
          Req: {
            Query: GenericQuery;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.Imports;
          };
        }

        export interface Patch {
          Req: {
            Query: GenericQuery;
            Params: Params;
            Body: Models.UpdateImports;
          };
          Res: {
            Body: Models.Imports;
          };
        }
      }

      export namespace statements {
        export interface Get {
          Req: {
            Query: Models.HdtQuery & Models.HdtQueryShort;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.QueryResults;
          };
        }
      }
      export namespace fragments {
        export interface Params extends datasets._account._dataset.Params {
          ext: string | undefined;
        }

        export interface Get {
          Req: {
            Query: Models.HdtQuery & { resultsSoFar?: number; limit?: number };
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.QueryResults;
          };
        }
      }
      export namespace describe {
        export interface Get {
          Req: {
            Query: Models.DescribeArguments;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.QueryResults;
          };
        }
      }
      export namespace terms {
        export interface Get {
          Req: {
            Query: Models.FindTermsQuery;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.FindTermsResult;
          };
        }
      }
      export namespace download {
        export interface Params extends datasets._account._dataset.Params {
          ext: string | undefined;
        }
        export interface Get {
          Req: {
            Query: GenericQuery & { graph?: string };
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: any;
          };
        }
      }
      export namespace jobs {
        export interface Delete {
          Req: {
            Query: Empty;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: {
              deletedJobIds: string[];
            };
          };
        }
        export interface Post {
          Req: {
            Query: Empty;
            Params: Params;
            Body: {
              type?: Models.IndexJobType;
              url?: string;
              downloadUrls?: string[];
              defaultGraphName?: string;
              baseIRI?: string;
              overwriteAll?: boolean;
              mergeGraphs?: boolean;
            };
          };
          Res: {
            Body: Models.IndexJob;
          };
        }
        export namespace _jobId {
          export interface Params extends datasets._account._dataset.Params {
            jobId: string;
          }
          export interface Get {
            Req: {
              Query: Empty;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: Models.IndexJob;
            };
          }
          export interface Delete {
            Req: {
              Query: Empty;
              Params: Params;
              Body: Empty;
            };
            Res: {
              Body: Empty;
            };
          }
          export namespace add {
            export interface Post {
              Req: {
                Query: Empty;
                Params: Params;
                Body: Empty;
              };
              Res: {
                Body: Models.IndexJob;
              };
            }
          }
          export namespace start {
            export interface Post {
              Req: {
                Query: Empty;
                Params: Params;
                Body: {
                  /** @deprecated Use the option when creating the job instead.
                   *  This option was deprecated on 2022-12-01 and the person
                   *  reading this should consider removing it. Triplydb-Js
                   *  versions (and thus Ratt version) from before 2022-12-01
                   *  could break when this option is removed. */
                  overwriteAll?: boolean;
                };
              };
              Res: {
                Body: Models.IndexJob;
              };
            }
          }
          export namespace reset {
            export interface Post {
              Req: {
                Query: Empty;
                Params: Params;
                Body: Empty;
              };
              Res: {
                Body: Models.IndexJob;
              };
            }
          }

          export namespace _sourceFileId {
            export interface Params extends datasets._account._dataset.jobs._jobId.Params {
              sourceFileId: string;
            }
            export interface Delete {
              Req: {
                Query: Empty;
                Params: Params;
                Body: Empty;
              };
              Res: {
                Body: Empty;
              };
            }
          }
        }
      }
    }
  }
}

export namespace queries {
  export interface Get {
    Req: {
      Query: GenericQuery & {
        since?: string;
        limit?: number;
        sortDirection?: "asc" | "desc";
        q?: string;
      };
      Params: Empty;
      Body: Empty;
    };
    Res: {
      Body: Models.Queries;
    };
  }

  export namespace _account {
    export interface Params {
      account: string;
    }
    export interface Get {
      Req: {
        Query: GenericQuery & {
          since?: string;
          sortBy?: "createdAt" | "updatedAt" | "name";
          sortDirection?: "asc" | "desc";
        };
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Models.Queries | string;
      };
    }

    export interface Post {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Models.QueryCreate;
      };
      Res: {
        Body: Models.Query;
      };
    }

    export namespace _query {
      export interface Params extends queries._account.Params {
        query: string;
        versionNr: string;
      }
      export interface Get {
        Req: {
          Query: SavedQueryArguments & GenericQuery;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Models.Query | string;
        };
      }

      export interface Patch {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Models.QueryMetaUpdate;
        };
        Res: {
          Body: Models.Query;
        };
      }

      export interface Post {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Models.QueryVersionUpdate;
        };
        Res: {
          Body: Models.Query;
        };
      }
      export interface Delete {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Empty;
        };
      }
      export namespace chown {
        export interface Post {
          Req: {
            Query: GenericQuery;
            Params: Params;
            Body: {
              toAccount: string;
            };
          };
          Res: {
            Body: Models.Query;
          };
        }
      }

      export namespace run {
        export interface Params extends queries._account._query.Params {
          ext: string | undefined;
        }
        export interface Get {
          Req: {
            Query: SavedQueryArguments & GenericQuery;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: any;
          };
        }
        export interface Post {
          Req: {
            Query: SavedQueryArguments & GenericQuery;
            Params: Params;
            Body: ContextDefinition | Frame;
          };
          Res: {
            Body: any;
          };
        }
      }
    }
  }
}

export namespace pipelines {
  export namespace _account {
    export interface Params {
      account: string;
    }
    export interface Post {
      Req: {
        Query: Empty;
        Params: Params;
        Body: Models.PipelineConfig;
      };
      Res: {
        Body: Models.Pipeline;
      };
    }
    export namespace _pipeline {
      export interface Params extends pipelines._account.Params {
        pipeline: string;
      }
      export interface Get {
        Req: {
          Query: Empty;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Models.Pipeline;
        };
      }
      export namespace cancel {
        export interface Post {
          Req: {
            Query: Empty;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Empty;
          };
        }
      }
    }
  }
}

export namespace jobs {
  export namespace _account {
    export interface Params {
      account: string;
    }
    export interface Get {
      Req: {
        Query: Empty;
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Array<Models.PipelineJob>;
      };
    }

    export namespace _job {
      export interface Params {
        account: string;
        job: string;
      }
      export interface Get {
        Req: {
          Query: Empty;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Models.PipelineJob;
        };
      }
      export namespace artifacts {
        export interface Get {
          Req: {
            Query: Empty;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: any;
          };
        }
      }
    }
  }
}

export namespace stories {
  export interface Get {
    Req: {
      Query: GenericQuery & {
        since?: string;
        limit?: number;
        sortDirection?: "asc" | "desc";
        q?: string;
      };
      Params: Empty;
      Body: Empty;
    };
    Res: {
      Body: Models.Story[];
    };
  }

  export namespace _account {
    export interface Params {
      account: string;
    }
    export interface Get {
      Req: {
        Query: GenericQuery & {
          since?: string;
          sortBy?: "createdAt" | "updatedAt" | "name";
          sortDirection?: "asc" | "desc";
        };
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Models.Story[];
      };
    }

    export interface Post {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Models.StoryCreate;
      };
      Res: {
        Body: Models.Story;
      };
    }

    export namespace _story {
      export interface Params extends stories._account.Params {
        story: string;
      }
      export interface Get {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Models.Story;
        };
      }

      export interface Patch {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Models.StoryUpdate;
        };
        Res: {
          Body: Models.Story;
        };
      }

      export interface Delete {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Empty;
        };
      }
      export namespace copy {
        export interface Post {
          Req: {
            Query: GenericQuery;
            Params: Params;
            Body: {
              toAccount: string;
            };
          };
          Res: {
            Body: Models.Story;
          };
        }
      }
      export namespace chown {
        export interface Post {
          Req: {
            Query: GenericQuery;
            Params: Params;
            Body: {
              toAccount: string;
            };
          };
          Res: {
            Body: Models.Story;
          };
        }
      }
      export namespace banner {
        export interface Get {
          Req: {
            Query: {
              v?: number;
              w?: number;
              h?: number;
            };
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.Story;
          };
        }
        export interface Post {
          Req: {
            Query: GenericQuery & {
              verbose?: string;
            };
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.Story;
          };
        }

        export interface Delete {
          Req: {
            Query: Empty;
            Params: Params;
            Body: Empty;
          };
          Res: {
            Body: Models.Story;
          };
        }
      }
    }
  }
}

export namespace search {
  export namespace datasets {
    export interface Get {
      Req: {
        Query: GenericQuery & {
          sortBy?: "createdAt" | "updatedAt" | "name";
          sortDirection?: "asc" | "desc";
          q?: string;
          offset?: number;
          limit?: number;
        };
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: Models.Dataset[];
      };
    }
  }
  export namespace stories {
    export interface Get {
      Req: {
        Query: GenericQuery & {
          offset?: number;
          limit?: number;
          sortBy?: "createdAt" | "updatedAt" | "name";
          sortDirection?: "asc" | "desc";
          q?: string;
        };
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: Models.Story[];
      };
    }
  }
  export namespace queries {
    export interface Get {
      Req: {
        Query: GenericQuery & {
          offset?: number;
          limit?: number;
          sortBy?: "createdAt" | "updatedAt" | "name";
          sortDirection?: "asc" | "desc";
          q?: string;
        };
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: Models.Queries;
      };
    }
  }
}

export namespace facets {
  export interface GenericFacetQuery {
    page?: number;
    asc?: "";
    q?: string;
    created?: "year" | "month" | "week" | "day";
  }
  export interface DqsFacetQuery {
    owner?: string;
    ownerAll?: "";
    access?: Models.AccessLevel;
  }
  export namespace datasets {
    export interface Get {
      Req: {
        Query: GenericQuery &
          GenericFacetQuery &
          DqsFacetQuery & {
            sort?: "created" | "name" | "statements" | "relevance";
            statementsMin?: number;
            statementsMax?: number;
            sparql?: "true";
            search?: "true";
            license?: DatasetLicenseId | "Unset";
            topic?: string;
            topicAll?: "";
          };
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: Models.DatasetFacets;
      };
    }
  }
  export namespace stories {
    export interface Get {
      Req: {
        Query: GenericQuery &
          GenericFacetQuery &
          DqsFacetQuery & {
            sort?: "created" | "name" | "relevance";
            dataset?: string;
            datasetAll?: "";
            query?: string;
            queryAll?: string;
          };
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: Models.StoryFacets;
      };
    }
  }
  export namespace queries {
    export interface Get {
      Req: {
        Query: GenericQuery &
          GenericFacetQuery &
          DqsFacetQuery & {
            sort?: "created" | "name" | "relevance";
            dataset?: string;
            datasetAll?: "";
            visualization?: string;
            visualizationAll?: "";
          };
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: Models.QueryFacets;
      };
    }
  }
  export namespace organizations {
    export interface Get {
      Req: {
        Query: GenericQuery &
          GenericFacetQuery & {
            sort?: "created" | "name" | "relevance";
            membersMin?: number;
            membersMax?: number;
            datasetsMin?: number;
            datasetsMax?: number;
            storiesMin?: number;
            storiesMax?: number;
            queriesMin?: number;
            queriesMax?: number;
          };
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: Models.OrganizationFacets;
      };
    }
  }
}

export namespace _some {
  export interface Get {
    Req: {
      Query: GenericQuery & {
        q?: string;
        o?: string; // owner account name
        e?: string; // exclude items, supports multiple values
        daf?: false; // disable account filter
      };
      Params: Empty;
      Body: Empty;
    };
    Res: {
      Body: {
        datasets: Models.SomeDataset[];
        stories: Models.SomeStory[];
        queries: Models.SomeQuery[];
        users: Models.SomeUser[];
        orgs: Models.SomeOrg[];
      };
    };
  }
}

//Used by route '/me' and route '/accounts/<me-name>'
export namespace account {
  export interface Get {
    Req: {
      Query: GenericQuery & {
        verbose?: "";
      };
      Params: Empty;
      Body: Empty;
    };
    Res: {
      Body: Models.Account;
    };
  }
  export interface Patch {
    Req: {
      Query: GenericQuery & {
        verbose?: string | null;
      };
      Params: Empty;
      Body: Models.AccountUpdate;
    };
    Res: {
      Body: Models.Account;
    };
  }
  export interface Delete {
    Req: {
      Query: {
        dissenting?: true;
      } & GenericQuery;
      Params: Empty;
      Body: Empty;
    };
    Res: {
      Body: Empty;
    };
  }
  export namespace session {
    export interface Delete {
      Req: {
        Query: GenericQuery;
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: Empty;
      };
    }
  }
  export namespace mfa {
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: {
          authenticator: boolean;
        };
      };
    }
    export namespace authenticator {
      export interface Post {
        Req: {
          Query: GenericQuery;
          Params: Empty;
          Body: Empty;
        };
        Res: {
          Body: {
            uri: string;
          };
        };
      }
      export interface Patch {
        Req: {
          Query: GenericQuery;
          Params: Empty;
          Body: {
            enabled: boolean;
          };
        };
        Res: {
          Body: Models.Account;
        };
      }
      export namespace confirm {
        export interface Post {
          Req: {
            Query: GenericQuery;
            Params: Empty;
            Body: { token: string };
          };
          Res: {
            Body: {
              codes: string[];
              generatedAt: string;
            };
          };
        }
      }
    }
  }
  export namespace orgs {
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: Models.Org[];
      };
    }
    export interface Post {
      Req: {
        Query: GenericQuery;
        Params: Empty;
        Body: Models.AccountUpdate;
      };
      Res: {
        Body: Models.Org;
      };
    }
  }
  export namespace members {
    export namespace _member {
      export interface Params {
        member: string;
      }
      export interface Get {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Models.OrgMember;
        };
      }
      export interface Delete {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Empty;
        };
      }
      export interface Patch {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: {
            role: Models.OrgRole;
          };
        };
        Res: {
          Body: Models.OrgMember;
        };
      }
    }
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: Models.OrgMember[];
      };
    }
    export interface Post {
      Req: {
        Query: GenericQuery;
        Params: Empty;
        Body: {
          accountName: string;
          role: Models.OrgRole;
        };
      };
      Res: {
        Body: Models.OrgMember;
      };
    }
  }

  export namespace tokens {
    export namespace _tokenId {
      export interface Params {
        tokenId: string;
      }
      export interface Get {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Models.Token;
        };
      }
      export interface Patch {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Partial<Models.Token>;
        };
        Res: {
          Body: Models.Token;
        };
      }

      export interface Delete {
        Req: {
          Query: GenericQuery;
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Empty;
        };
      }
    }
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: Empty;
        Body: Empty;
      };
      Res: {
        Body: Models.Token[];
      };
    }
    export interface Post {
      Req: {
        Query: GenericQuery;
        Params: Empty;
        Body: Models.CreateToken;
      };
      Res: {
        Body: Models.Token;
      };
    }
  }
}

export namespace accounts {
  export import _account = account;
  export interface Get {
    Req: {
      Query: {
        type?: Models.AccountType;
        q?: string;
        offset?: number;
        substring?: string;
        sortBy?: "createdAt" | "updatedAt" | "accountNameLower";
        sortDirection?: "asc" | "desc";
        limit?: number;
      } & GenericQuery;
      Params: Empty;
      Body: Empty;
    };
    Res: {
      Body: Models.Account[];
    };
  }
}

export namespace topics {
  export interface Get {
    Req: {
      Query: GenericQuery & {
        q?: string;
        since?: string;
        limit?: number;
      };
      Params: Empty;
      Body: Empty;
    };
    Res: {
      Body: Models.Topic[];
    };
  }

  export interface Put {
    Req: {
      Query: GenericQuery;
      Params: Empty;
      Body: Models.TopicUpdates;
    };
    Res: {
      Body: Models.Topic[];
    };
  }

  export interface Patch {
    Req: {
      Query: GenericQuery;
      Params: Empty;
      Body: Models.TopicUpdates;
    };
    Res: {
      Body: Models.Topic[];
    };
  }

  export namespace _topicId {
    export interface Params {
      topicId: string;
    }
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: {
          topicId: string;
        };
        Body: Empty;
      };
      Res: {
        Body: Models.Topic;
      };
    }

    export interface Patch {
      Req: {
        Query: GenericQuery;
        Params: {
          topicId: string;
        };
        Body: Models.TopicUpdate;
      };
      Res: {
        Body: Models.Topic;
      };
    }

    export interface Delete {
      Req: {
        Query: GenericQuery;
        Params: {
          topicId: string;
        };
        Body: Empty;
      };
      Res: {
        Body: Empty;
      };
    }
  }
}
export namespace redirect {
  export interface Get {
    Req: {
      Query: GenericQuery & {
        iri?: string;
      };
      Params: {
        iri?: string;
      };
      Body: Empty;
    };
    Res: {
      Body: Empty;
    };
  }
}

export namespace search {
  export interface Get {
    Req: {
      Query: GenericQuery & {
        q?: string;
        accountName?: string;
      };
      Params: Empty;
      Body: Empty;
    };
    Res: {
      Body: Models.SearchResults;
    };
  }
}

export namespace shortUrl {
  export interface Get {
    Req: {
      Query: GenericQuery & {
        shortUrl: string;
      };
      Params: Empty;
      Body: Empty;
    };
    Res: {
      Body: Models.ShortUrl;
    };
  }
  export interface Post {
    Req: {
      Query: GenericQuery;
      Params: Empty;
      Body: {
        longUrl: string;
      };
    };
    Res: {
      Body: Models.ShortUrl;
    };
  }
}
export namespace s {
  export namespace _short {
    export interface Params {
      short: string;
    }
    export interface Get {
      Req: {
        Query: GenericQuery;
        Params: Params;
        Body: Empty;
      };
      Res: {
        Body: Empty;
      };
    }
  }
}

export namespace web {
  export interface Params {}
  export namespace auth {
    export interface Params {}
    export namespace oauth {
      export interface Params {}
      export interface Get {
        Req: {
          Query: GenericQuery & { from: Models.RegisterOrLogin };
          Params: Params;
          Body: Empty;
        };
        Res: {
          Body: Empty;
        };
      }
    }
  }
}
export namespace _console {
  export interface Params {}
  export namespace sparql {
    export interface Post {
      Req: {
        Query: {};
        Params: {
          ext: string | undefined;
        };
        Body: {
          queryString: string;
          account: string;
          dataset: string;
          extraNquads?: {
            /** the data must be passed as an nquads string */
            positive?: string;
            /** the data must be passed as an nquads string */
            negative?: string;
          };
        };
      };
      Res: {
        Body: any;
      };
    }
  }
}
