import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { FormField } from "#components/index.ts";
import { IGNORED_EDITOR_PREDICATE_IRIS } from "#containers/DataEditor/helpers.ts";
import PropertyGroup from "#containers/DataEditor/PropertyGroup.tsx";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import { GlobalState } from "#reducers/index.js";
import { BlankNodeProperty, Property, ResourceEditorDescriptions } from "#reducers/resourceEditorDescriptions.ts";
import DashViewer from ".";
import * as styles from "./style.scss";

const NO_GROUP = "none";

interface Props {
  dsId: string;
  description: ResourceEditorDescriptions | BlankNodeProperty;
}

const PropertiesViewer: React.FC<Props> = ({ dsId, description }) => {
  const classDescription = useSelector((state: GlobalState) =>
    dsId && description.type ? state.resourceEditorDescriptions[dsId]?.classShapes[description.type] : undefined,
  );
  const unusedProperties = Object.entries(description?.properties || {}).filter(
    ([propertyIri]) =>
      !classDescription?.groups
        ?.flatMap((el) => el.properties)
        .map((el) => el?.path)
        .includes(propertyIri) && !IGNORED_EDITOR_PREDICATE_IRIS.includes(propertyIri),
  );

  return (
    <div className="flex column g-5">
      {classDescription?.groups?.map((group, idx) => {
        if (group.groupIri === NO_GROUP) {
          return (
            <div key={NO_GROUP} className="flex column g-3">
              {group.properties?.map((property) => {
                const properties = description.properties?.[property.path];
                if (properties === undefined || properties.length === 0) return null;
                return (
                  <PropertyShape
                    key={property.propertyShape}
                    properties={properties}
                    propertyName={property.propertyShapeLabel}
                  />
                );
              })}
            </div>
          );
        }
        return (
          <PropertyGroup key={group.groupIri ?? idx} groupName={group.groupName}>
            <div className="px-3 flex column g-3">
              {group.properties?.map((property) => {
                const properties = description.properties?.[property.path];
                if (properties === undefined || properties.length === 0) return null;
                return (
                  <PropertyShape
                    key={property.propertyShape}
                    properties={properties}
                    propertyName={property.propertyShapeLabel}
                  />
                );
              })}
            </div>
          </PropertyGroup>
        );
      })}
      {unusedProperties.length > 0 && (
        <Accordion variant="outlined">
          <AccordionSummary>Extra properties</AccordionSummary>
          <AccordionDetails className="flex column g-3">
            {unusedProperties?.map(([propertyIri, properties]) => (
              <PropertyShape properties={properties} key={propertyIri} />
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default PropertiesViewer;

export const PropertyShape: React.FC<{ propertyName?: string; properties: Property[] }> = ({
  properties,
  propertyName,
}) => {
  const applyPrefixes = useApplyPrefixes();
  if (!properties?.map) return null;
  return (
    <FormField
      label={propertyName || applyPrefixes(properties[0]?.predicate)}
      breakPoint={1}
      disableInputFieldAlignment
    >
      <div className="flex column g-1">
        {properties?.map((property, index) => (
          <DashViewer
            key={
              property.value + (property.nodeKind === "Literal" ? property.language || property.datatype : "") + index
            }
            property={property}
          />
        ))}
      </div>
    </FormField>
  );
};
