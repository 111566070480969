import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Box, ButtonProps, Container, TextField, Tooltip } from "@mui/material";
import dedent from "dedent";
import * as React from "react";
import { useLocation } from "react-router";
import { Button, Dialog, FontAwesomeIcon, LoadingButton } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString } from "#helpers/utils.ts";
import { refreshDatasetsInfo, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { getGraphs } from "#reducers/graphs.ts";
import { getDescription } from "#reducers/resourceEditorDescriptions.ts";

interface Props {
  buttonLabel: string;
  title: string;
  icon?: FontAwesomeIconProps["icon"];
  confirmationLabel: string;
  newStatus: string;
  color?: ButtonProps["color"];
  disabled?: string;
}

export const ResourceAction: React.FC<Props> = ({
  buttonLabel,
  title,
  confirmationLabel,
  newStatus,
  color,
  icon,
  disabled,
}) => {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const dispatch = useDispatch();
  const resource = parseSearchString(useLocation().search).resource as string;

  return (
    <>
      <Tooltip title={disabled}>
        <div>
          <Button
            color={color}
            elevation
            onClick={() => setOpen(true)}
            title={title}
            disabled={!!disabled}
            startIcon={icon && <FontAwesomeIcon icon={icon} />}
            size="small"
          >
            {buttonLabel}
          </Button>
        </div>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth title={title} closeButton>
        <Container className="pb-3">
          <p>Show the following editing note to the editors</p>
          <form
            className="px-5 pb-5"
            onSubmit={async (e) => {
              e.preventDefault();
              setSaving(true);

              const textField = e.currentTarget[0] as HTMLTextAreaElement;

              let editorNote = textField.value.trim() || "";
              if (editorNote) editorNote = `"${editorNote}"`;

              const query = dedent`
                prefix skos: <http://www.w3.org/2004/02/skos/core#>
                prefix meta: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>

                delete {
                  ?id meta:status ?status;
                      meta:editorialNote ?oldNote.
                } insert {
                  ?id meta:status "${newStatus}";
                      meta:editorialNote ?note
                } where {
                  bind(<${resource}> as ?id)
                  optional {
                    ?id meta:status ?status
                  }
                  optional {
                    ?id meta:editorialNote ?oldNote
                  }
                  optional {
                    values ?note {
                      ${editorNote}
                    }
                  }

                }`;

              const body = new FormData();
              body.set("update", query);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                }),
              );
              await dispatch<typeof getDescription>(getDescription({ dataset: currentDs, resource: resource }));

              setSaving(false);
              setOpen(false);
            }}
          >
            <TextField label="editing note" multiline minRows={3} disabled={saving} fullWidth variant="filled" />
            <Box className="mt-3">
              <Button variant="text" onClick={() => setOpen(false)} disabled={saving}>
                Cancel
              </Button>
              <LoadingButton color={color} type="submit" loading={saving} disabled={saving} variant="contained">
                {title}
              </LoadingButton>
            </Box>
          </form>
        </Container>
      </Dialog>
    </>
  );
};

export default ResourceAction;
